import simpleLogo from "../images/logo-simple-small.png";


export default function HomepageFooter() {
    return (
        <footer style={{ backgroundColor: '#0b6156', color: '#eee4eb' }}>
            <div className="container" style={{ paddingBottom: "3em" }}>
                <div className="row align-items-center">
                    <div className="col-md-6 text-left">
                        <img src={simpleLogo} alt="Logo of Elk AI Description Generator" style={{
                            width: "30%",
                            margin: "1em 0",
                            borderRadius: "15px",
                        }} />
                        <p>
                            Ready to boost your sales?
                        </p>
                        <p>
                            <a className="btn btn-light" role="button" href="https://apps.shopify.com/elk-ai-description-generator">Install the App</a>
                        </p>
                    </div>
                    <div className="col-md-6">
                        <p>
                            <a href="/" style={{ color: '#eee4eb' }}>Home</a>
                        </p>
                        <h5>Support</h5>
                        <p>Email us at: <a href="mailto:support@elkapp.com" style={{ color: '#8ee4eb' }}>support@elkai.top</a></p>
                        <h5>Resources</h5>
                        <p>
                            <a href="/privacy-policy" style={{ color: '#eee4eb' }}>Privacy Policy</a>
                        </p>
                    </div>
                </div>
                <p>© 2024 Elk AI Description Generator.</p>
            </div>
        </footer>
    );
}